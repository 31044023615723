<template>
  <div>
    <DataGridFactory
      :dataSource="store.DocCstPisCofins.table"
      :enabledActions="false"
      :nameToExport="'Totais Pis/Cofins por CST'"
    >
      <template v-slot:column>
        <DxColumn data-field="cstpis" alignment="right" caption="CST PIS" />
        <DxColumn
          data-field="cstcofins"
          alignment="right"
          caption="CST COFINS"
        />
        <DxColumn
          data-field="vprod"
          data-type="number"
          caption="Produtos"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vdesc"
          data-type="number"
          caption="- Descontos"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vfrete"
          data-type="number"
          caption="+ Fretes"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vseg"
          data-type="number"
          caption="+ Seguros"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="voutro"
          data-type="number"
          caption="+ Outros"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vbcpis"
          data-type="number"
          caption="= BC PIS"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vpis"
          data-type="number"
          caption="Valor PIS"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vbccofins"
          data-type="number"
          caption="= BC Cofins"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="vcofins"
          data-type="number"
          caption="Valor COFINS"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
      </template>
      <template v-slot:footer>
        <DxExport :enabled="true" />
        <DxSummary>
          <DxTotalItem
            column="cstpis"
            alignment="left"
            summary-type="count"
            display-format="{0} Registros"
         />
          <DxTotalItem
            column="vprod"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vdesc"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vfrete"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vseg"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="voutro"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vbcpis"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vpis"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vbccofins"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="vcofins"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
        </DxSummary>
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>